<template lang="pug">
div.view_container
  Header
  AddIntroVideo(
    v-if="!isSafari && !isIOS"
  )
  AddIntroVideoTwilio(
    v-if="isSafari && !isIOS"
  )
  AddIntroVideoWithFileUpload(
    v-if="isIOS"
  )
</template>

<script>
/* global gtag */
import config from '../appConfig'
import AddIntroVideo from '../components/AddIntroVideo'
import AddIntroVideoTwilio from '../components/AddIntroVideoTwilio'
import AddIntroVideoWithFileUpload from '../components/AddIntroVideoWithFileUpload'
import Header from '../components/Header'

export default {
  name: 'AddIntroVideoContainer',
  props: {
  },
  components: {
    AddIntroVideo,
    AddIntroVideoTwilio,
    AddIntroVideoWithFileUpload,
    Header,
  },
  data () {
    return {
      isIOS: false,
      isSafari: false,
    }
  },
  apollo: {

  },
  methods: {

  },
  beforeMount: function () {
    this.isSafari = config.isSafari
    this.isIOS = config.isIOS
  },
  mounted: function() {
    // window.vue = this
    if (this.isSafari && !this.isIOS) {
      this.$toast.warning(
        "Video recording on safari is very slow, we recommend chrome:  https://www.google.com/chrome",
        {timeout: false}
      )
    }

    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/add_intro_video'})
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {

}
</style>
