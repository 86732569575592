a<template lang="pug">
div.video_record
  div#title Record a video explaining what you want people to record for {{ recipientName }}
  div.video_controls
    div.loading(v-show="loading")
      img(v-bind:src="loadingSrc")
    div.wrapper(v-show="!loading")
      img.start(v-on:click="startRecording" ref="start" style="touch-action: manipulation" :src="recordStartButtonSrc" :title="recordButtonTitle")
      img.start(v-show="hasRecording && !isPlaying" v-on:click="playLocalFile" ref="play" style="touch-action: manipulation" :src="playButtonSrc")
      img.stop(v-show="hasRecording && isPlaying" v-on:click="stopPlayback" ref="stop" style="touch-action: manipulation" :src="stopButtonSrc" :title="stopButtonTitle")
      img.next(v-show="hasRecording" v-on:click="saveRecording" ref="save" style="touch-action: manipulation" :src="nextButtonSrc" :title="nextButtonTitle")
      div.file_upload(v-show="!loading")
        input#file_input(type="file" v-on:change="fileUpload" ref="file" style="display:none" accept="video/mp4,video/x-m4v,video/*")
      div.link_skip
        router-link(v-show="hasIntroVideo"  style="touch-action: manipulation" to="invite") Skip
  video.playback(
        autoplay
        playsinline
        loop
        muted
        )
  video#preview(
        autoplay
        playsinline
        loop
        muted
        )

</template>

<script>
import { UPDATE_PROJECT_MUTATION } from '../graphql/mutations'
import config from '../appConfig'

export default {
  name: 'AddIntroVideoWithFileUpload',
  props: {
  },
  components: {
  },
  computed: {
    hasIntroVideo () {
      return this.currentProject && this.currentProject.introVideoUrl
    },
    recordButtonTitle () {
      if (this.hasRecording) { return "Re-Record" }
      return "Record"
    },
    stopButtonTitle () {
      return "Stop playback"
    },
    nextButtonTitle () {
      return "Save recording and go to next step"
    },
    nextButtonSrc () {
      return process.env.BASE_URL + "video_save_button.svg"
    },
    recordStartButtonSrc () {
      return process.env.BASE_URL + "record_start_button.svg"
    },
    playButtonSrc () {
      return process.env.BASE_URL + "play_button.svg"
    },
    stopButtonSrc () {
      return process.env.BASE_URL + "playback_stop_button.svg"
    },
    muteButtonSrc () {
      return this.videoMuted ? process.env.BASE_URL + "mute_off_button.svg" : process.env.BASE_URL + "mute_on_button.svg"
    },
    muteButtonTitle () {
      return this.videoMuted ? "Unmute" : "Mute"
    },
    loadingSrc () {
      return process.env.BASE_URL + "loading.gif"
    },
    recipientName () {
      return this.currentProject.recipientName
    },
    currentProject () {
      return this.$store.state.currentProject
    },
    currentUser () {
      return this.$store.state.user.user
    },
    saveButtonTitle () {
      return this.loading ? "Uploading video..." : "Next"
    },
    RecordButtonTitle () {
      return this.hasRecording ? "Re-record" : "Record"
    },
    introVideoUrl () {
      return this.currentProject.introVideoUrl
    },
  },
  watch: {

  },
  data () {
    return {
      hasRecording: false,
      isPlaying: false,
      videoMuted: true,
      loading: false,
      file: null,
    }
  },
  apollo: {

  },
  methods: {
    toggleMute: function() {
      var video = document.querySelector('video.playback')
      video.muted = !video.muted
      this.videoMuted = video.muted
    },
    saveRecording: function() {
      if (this.hasRecording) {
        this.hasRecording = false
        this.$router.push({name:"InvitePeople"})
      }
    },
    loadLocalFile: function () {
      this.stopStreaming()
      this.resetPlayback()

      if (!this.file) return
      const video = document.querySelector("video.playback")
      video.loop = true
      video.autoplay = true
      video.muted = config.isSafari
      video.srcObject = null
      video.src = window.webkitURL.createObjectURL(this.file)
      video.load()
      video.pause()
    },
    playLocalFile: function() {
      const video = document.querySelector("video.playback")
      video.play().then(()=> {
        this.isPlaying = true
      }).catch((error) =>{
        console.log('play local file error: '+error)
      })
    },
    playRecording: function() {
      this.stopStreaming()
      this.resetPlayback()

      var video = document.querySelector("video.playback")
      video.loop = true
      video.autoplay = true
      video.muted = config.isSafari
      this.videoMuted = video.muted
      video.srcObject = null
      // video.src = this.introVideoUrl
      // video.load()

      const fetchVideoAndPlay = () => {
        fetch(this.introVideoUrl)
        .then(response => response.blob())
        .then(blob => {
          window.blob = blob
          const newBlob = new Blob([blob],{type:'video/mp4'})
          video.srcObject = null
          video.src = window.webkitURL.createObjectURL(newBlob)

          return video.play()
        })
        .then(() => {
          // Video playback started
          console.log('playing fetched video blob')
          // alert('playing')
        })
        .catch((error) => {
          // Video playback failed
          console.log(error)
          // alert(error)
        })
      }
      fetchVideoAndPlay()
      // video.play().catch((e)=>{console.log(e)})
    },
    stopPlayback: function() {
      var video = document.querySelector("video.playback")
      video.pause()
      this.isPlaying = false
    },
    resetPlayback: function() {
      var video = document.querySelector("video.playback")
      if (video.src) {
        (window.webkitURL || window.URL).revokeObjectURL(video.src)
        video.removeAttribute('src');
        video.load()
      }
    },
    startRecording: function() {
      this.stopStreaming()
      this.resetPlayback()
      this.setupVideoPreview()

      this.hasRecording = false
      this.$refs.file.click()
    },
    fileUpload: function ({ target: { files = [] } }) {
      if (!files.length) {
        return
      }

      this.file = files[0]

      this.loading = true
      this.$apollo.mutate({
        mutation: UPDATE_PROJECT_MUTATION,
        variables: {
          input: {
            id: this.currentProject.id,
            introVideo: files[0]
          }
        },
        context: {
          hasUpload: true
        },
      }).then((data) => {
        console.log(data)
        this.$store.commit('setProject',data.data.updateProject)
        this.loading = false
        this.hasRecording = this.introVideoUrl ? true : false
        // this.playRecording()
        this.loadLocalFile()
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    stopStreaming: function() {
      var video = document.querySelector("video#preview")
      const stream = video.srcObject

      if (stream == null) return

      const tracks = stream.getTracks()

      tracks.forEach(function(track) {
        track.stop()
      })

      video.srcObject = null
    },
    setupVideoPreview: async function() {

      try {
        const stream = await navigator.mediaDevices.getUserMedia(config.mediaDeviceConstraints)
        var video = document.querySelector("video#preview")
        video.muted = true // otherwise audio feedback will occur
        video.srcObject = stream
        // window.stream = stream  // make stream available to console
        // console.log("getUserMedia() got stream:", stream)
      } catch (e) {
        console.error('navigator.mediaDevices.getUserMedia error:', e)
        if (/Permission denied/i.test(e)) {
          this.$toast.error("You have blocked this page from accessing your camera and microphone.  In the address bar, look for the icon that shows site permissions, and click to approve camera and microphone access and reload the page.",{timeout: false})
        }
      }

    },
    openFullscreen: function(selector) {
      var elem = document.querySelector(selector)

      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen()
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.stopStreaming()
    this.resetPlayback()
    next()
  },
  beforeUnmount: function() {
    this.stopStreaming()
    this.resetPlayback()
  },
  mounted: function () {
    window.vue = this
    this.setupVideoPreview()
  }
}
</script>

<style>
#preview video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100
}


</style>

<style scoped>

#title {
  position: absolute;
  z-index: 500;
  top: 25%;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 28px;
  font-weight: regular;
  text-shadow: 0 2px 40px rgba(0,0,0,0.45);
}

.video_record {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  overflow: hidden;
}


.overlay {
  display: block;
  position: absolute;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
}

.link_skip {
  text-align: center;
  margin-top: 10px;
}

.title {
  position: absolute;
  z-index: 500;
  top: 45%;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  text-shadow: 0 2px 40px rgba(0,0,0,0.45);
}

.file_upload {
  display: inline-block;
}
.file_upload a {
  padding: 4px 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  color: #ffffff;
}

</style>
