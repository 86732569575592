<template lang="pug">
div.video_record.fill
  div#title Record a video explaining what you want people to record for {{ recipientName }}
  div.select_input_device_container(v-show="showSelectDevices")
    div.select_input_device
      Multiselect(
        v-model="selectedVideoLabel",
        v-bind:options="inputVideoDeviceLabels",
        :searchable="false",
        :close-on-select="true",
        :show-labels="false"
        placeholder="Select video input"
      )
    div.select_input_device
      Multiselect(
        v-model="selectedAudioLabel",
        v-bind:options="inputAudioDeviceLabels",
        :searchable="false",
        :close-on-select="true",
        :show-labels="false"
        placeholder="Select audio input"
      )
  div.video_controls
    input#keyshortcut.hideaway(v-on:keyup="keySpaceToSubmit")
    div.loading(v-show="loading")
      img(v-bind:src="loadingSrc")
    div.wrapper(v-show="!loading")
      img.start(v-show="!isRecording" v-on:click="startRecording" ref="start" style="touch-action: manipulation" :src="recordStartButtonSrc" :title="recordButtonTitle")
      img.start(v-show="hasRecording && !isPlaying" v-on:click="playRecording" ref="play" style="touch-action: manipulation" :src="playButtonSrc")
      img.stop(v-show="isRecording || (hasRecording && isPlaying)" v-on:click="stopRecording" ref="stop" style="touch-action: manipulation" :src="stopButtonSrc" :title="stopButtonTitle")
      img.next(v-show="hasRecording" v-on:click="saveRecording" ref="save" style="touch-action: manipulation" :src="nextButtonSrc" :title="nextButtonTitle")
    img.settings(v-show="showSettings" v-on:click="showSelectDevices = !showSelectDevices" ref="settings" style="touch-action: manipulation" :src="settingsIconSrc" :title="settingsTitle")
    div.link_skip
      router-link(v-show="hasIntroVideo"  style="touch-action: manipulation" to="invite") Skip
  video#preview(autoplay
        playsinline
        muted
        z-index=10
        )
  video#playback(autoplay
        playsinline
        loop
        v-bind:z-index="videoPlaybackIndex"
  )

</template>

<script>

import { UPDATE_PROJECT_MUTATION } from '../graphql/mutations'
import {_} from 'vue-underscore';
import Multiselect from 'vue-multiselect'
import config from '../appConfig'

export default {
  name: 'AddIntroVideo',
  props: {
  },
  components: {
    Multiselect
  },
  computed: {
    loadingSrc () {
      return process.env.BASE_URL + "loading.gif"
    },
    settingsIconSrc () {
      return process.env.BASE_URL + "video_settings_button.svg"
    },
    playButtonSrc () {
      return process.env.BASE_URL + "play_button.svg"
    },
    settingsTitle () {
      return "change video/audio device input"
    },
    recordButtonTitle () {
      if (this.hasRecording) { return "Re-Record" }
      return "Record"
    },
    stopButtonTitle () {
      if (this.hasRecording) { return "Pause playback" }
      return "Stop recording"
    },
    nextButtonTitle () {
      return "Save recording and go to next step"
    },
    nextButtonSrc () {
      return process.env.BASE_URL + "video_save_button.svg"
    },
    recordStartButtonSrc () {
      return process.env.BASE_URL + "record_start_button.svg"
    },
    stopButtonSrc () {
      if (this.isRecording) {
        return process.env.BASE_URL + "record_stop_button.svg"
      } else if (this.hasRecording) {
        const video = document.querySelector('video#playback')
        if (video.paused) {
          return process.env.BASE_URL + "playback_stop_button.svg"
        }

      }
      return process.env.BASE_URL + "playback_stop_button.svg"
    },
    showSettings () {
      return !config.isSafari
    },
    recipientName () {
      return this.currentProject.recipientName
    },
    currentProject () {
      return this.$store.state.currentProject
    },
    hasIntroVideo () {
      return this.currentProject && this.currentProject.introVideoUrl
    },
    RecordButtonTitle () {
      return this.hasRecording ? "Re-record" : "Record"
    },
    inputVideoDevices () {
      return _.where(this.inputDevices, {kind: "videoinput"})
    },
    inputAudioDevices () {
      return _.where(this.inputDevices, {kind: "audioinput"})
    },
    inputVideoDeviceLabels () {
      return _.map(this.inputVideoDevices, function(inputDevice) {
        return inputDevice.label
      })
    },
    inputAudioDeviceLabels () {
      return _.map(this.inputAudioDevices, function(inputDevice) {
        return inputDevice.label == "" ? "internal" : inputDevice.label
      })
    },
  },
  watch: {
    selectedVideoLabel: function() {
      const selectedVideoDevice = _.findWhere(this.inputVideoDevices, {label: this.selectedVideoLabel})
      this.allStop()
      this.mediaDeviceConstraints.video.deviceId = {exact: selectedVideoDevice.deviceId}
      this.setupVideoPreview()
    },
    selectedAudioLabel: function() {
      const selectedAudioDevice = _.findWhere(this.inputAudioDevices, {label: this.selectedAudioLabel})
      this.allStop()
      this.mediaDeviceConstraints.audio.deviceId = {exact: selectedAudioDevice.deviceId}
      this.setupVideoPreview()
    },
  },
  data () {
    return {
      showSelectDevices: false,
      inputDevices: [],
      selectedVideoLabel: null,
      selectedAudioLabel: null,
      isRecording: false,
      isPlaying: false,
      hasRecording: false,
      recordedBlobs: [],
      mediaRecorder: null,
      videoPlaybackIndex: 5,
      videoFile: null,
      videoFileName: "intro_video_to_project_"+this.$store.state.currentProject.id,
      loading: false,
      mediaDeviceConstraints: config.mediaDeviceConstraints,
    }
  },
  apollo: {
  },
  methods: {
    getDevices: function() {
      navigator.mediaDevices.enumerateDevices().then((inputDevices) =>{
        this.inputDevices = inputDevices
      }).catch((error) => {
        console.log(error)
      })
    },
    updateProject: async function() {
      this.loading = true
      this.$apollo.mutate({
        mutation: UPDATE_PROJECT_MUTATION,
        variables: {
          input: {
            id: this.currentProject.id,
            introVideo: this.videoFile
          }
        },
        context: {
          hasUpload: true
        },
      }).then((data) => {
        console.log(data)
        this.$store.commit('setProject',data.data.updateProject)
        this.loading = false
        this.$router.push({name:"InvitePeople"})
      }).catch((error) => {
        console.error(error)
        this.loading = false
      })
    },
    saveRecording: function() {
      this.videoFile = new File(this.recordedBlobs, this.videoFileName+".mp4", {type: this.recordedBlobs[0].type})
      this.updateProject()
    },
    handleDataAvailable: function(event) {
      // console.log('handleDataAvailable', event)
      if (event.data && event.data.size > 0) {
        this.recordedBlobs.push(event.data)
      }
    },
    loadRecording: function () {
      const superBuffer = new Blob(this.recordedBlobs, {type: 'video/h264'})
      const video = document.querySelector("video#playback")
      video.srcObject = null
      video.src = window.URL.createObjectURL(superBuffer)
      video.controls = false
      video.pause()
    },
    playRecording: function() {
      // console.log('----------playRecording')
      const video = document.querySelector("video#playback")
      var playPromise = video.play()
      if (playPromise !== undefined) {
        playPromise.then(() => {
          // Automatic playback started!
          this.videoPlaybackIndex = 15
          this.isPlaying = true
        }).catch(function(error) {
          // Automatic playback failed.
          // Show a UI element to let the user manually start playback.
          console.error(error)
        });
      }
    },
    stopRecording: function() {
      document.querySelector('#keyshortcut').focus()

      if (this.hasRecording) {
        var video = document.querySelector("video#playback")
        video.pause()
        this.isPlaying = false
      } else {
        if (this.mediaRecorder != null) this.mediaRecorder.stop()
        this.hasRecording = true
        this.isRecording = false
      }
    },
    resetRecording: function() {
      this.recordedBlobs = []
      this.hasRecording = false
      this.videoPlaybackIndex = 5
      var video = document.querySelector("video#playback")
      if (video.src) {
        window.URL.revokeObjectURL(video.src)
        video.src = null
      }
    },
    startRecording: function() {
      this.resetRecording()
      document.querySelector('#keyshortcut').focus()

      try {
        this.mediaRecorder = new MediaRecorder(window.stream, config.recordingOptions)
      } catch (e) {
        console.error('Exception while creating MediaRecorder:', e)
        return
      }

      // console.log('Created MediaRecorder', this.mediaRecorder, 'with options', options);
      this.mediaRecorder.onstop = (event) => {
        // console.log('Recorder stopped: ', event);
        // console.log('Recorded Blobs: ', this.recordedBlobs);
        if (event.type == "stop") {
          this.loadRecording()
        }
      };
      this.mediaRecorder.ondataavailable = this.handleDataAvailable;
      this.mediaRecorder.start()
      this.isRecording = true
      // console.log('MediaRecorder started', this.mediaRecorder);
    },
    keySpaceToSubmit: function(e) {
      // console.log(e.keyCode)
      if (e.keyCode === 32) {
        if (this.isRecording) {
          this.stopRecording()
        } else {
          this.startRecording()
        }
      }
    },
    setupVideoPreview: async function () {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(this.mediaDeviceConstraints)
        var video = document.querySelector("video#preview")
        video.muted = true // otherwise audio feedback will occur
        video.srcObject = stream
        window.stream = stream  // make stream available to console
        // console.log("getUserMedia() got stream:", stream)
      } catch (e) {
        console.error('navigator.mediaDevices.getUserMedia error:', e)
        if (/Permission denied/i.test(e)) {
          this.$toast.error("You have blocked this page from accessing your camera and microphone.  In the address bar, look for the icon that shows site permissions, and click to approve camera and microphone access and reload the page.",{timeout: false})
        }
      }

    },
    stopStreaming: function() {
      var video = document.querySelector("video#preview")
      const stream = video.srcObject

      if (stream == null) return

      const tracks = stream.getTracks()

      tracks.forEach(function(track) {
        track.stop();
      });

      video.srcObject = null;
    },
    openFullscreen: function() {
      var elem = document.querySelector(".video_record")

      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen()
      }
    },
    allStop: function() {
      this.stopStreaming()
      this.stopRecording()
      this.resetRecording()
    },
  },
  beforeRouteLeave(to, from, next) {
    this.allStop()
    next()
  },
  beforeUnmount: function() {
    this.allStop()
  },
  mounted: function () {
    this.setupVideoPreview()
    this.getDevices()
    document.querySelector('#keyshortcut').focus()
  }
}
</script>

<style scoped>
.video_record {
  position: absolute;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  overflow: hidden;
}

.overlay {
  display: block;
  position: absolute;
}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.fill {
  width: 100%;
  height: 100%;
}

.link_skip {
  text-align: center;;
  margin-top: 10px;
}

#title {
  position: absolute;
  z-index: 500;
  top: 25%;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 28px;
  font-weight: regular;
  text-shadow: 0 2px 40px rgba(0,0,0,0.45);
}

.select_input_device_container {
  position: absolute;
  z-index: 800;
  bottom: 30%;
  width: 260px;
  border: none;
  text-align: left;
}

.select_input_device {
  margin: 0 10px;
  display: block;
  background-color: RGBA(28, 32, 54, .28);
  border: none;
  border-radius: 10px;
  padding: 12px 20px;
  cursor: pointer;
  color: rgba(255,255,255,0.8);
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 2px 10px rgba(0,0,0,0.20);
  margin-bottom: 10px;
}


.settings {
  cursor: pointer;
  display: block;
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 30%;
  left: 2%;
}


</style>
